// Packages
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from "react-router-dom";


// Routes
import MainRoutes from 'routes/main';


// Other
import Auth from './other/Auth';
import AppStatus from './other/AppStatus';
import Config from './other/Config';


// Assets
import './assets/styles.css';


const App = () => 
{
	const navigate = useNavigate();

	
	/**
	 * This is triggered by the Auth0 SDK when a successful auth has taken place
	 * - We are using it to redirect to the initial requested URL
	 */
	const onRedirectCallback = (appState) => {
		
  		navigate(appState?.returnTo || window.location.pathname);
	}; 
	
	return (
		
		<Auth0Provider
            domain={Config.AUTH0_DOMAIN}
            clientId={Config.AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            scope={Config.AUTH0_SCOPES}
            audience="https://api.ambassador.tropicskincare.com"
            onRedirectCallback={onRedirectCallback}
        >
			<Auth>
				<AppStatus>
				<MainRoutes/>
				</AppStatus>
			</Auth>
		</Auth0Provider>
	);
}

export default App;