// Packages
import { Routes, Route } from "react-router-dom"


// Routes
import MiscRoutes from 'routes/misc';


const MainRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<MiscRoutes/>}/>
		</Routes>
	);
}


export default MainRoutes;