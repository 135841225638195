// Components
import Tiles from 'components/Tiles';
import Banner from 'components/Banner';


const Screen = () => {

	return (
		<>
		<Banner photo="header-default-2.jpg"
				title="Ambassador Access" 
				description="From product and business training to social media assets and marketing tools, immerse yourself in all that your Ambassador-exclusive platforms have to offer. "
		/>

		{/*
		<p style={{
			color: '#BC2354',
			fontWeight: 'bold',
			fontSize: '20px',
			padding: '0px 20px'
		}}>We're aware that some people are currently experiencing issues when attempting to access the Lounge via your new Ambassador Access.<br/>Thank you for your patience as we work on resolving this as soon as possible for you.</p>
		*/}
		
    	<Tiles/>
  		</>
	);
}

export default Screen;