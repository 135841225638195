// Libraries
import Iframe from 'react-iframe';


// Components
import Banner from 'components/Banner';


// Assets
import './styles.css';

const Screen = () => {


	return (
		<>
		<Banner photo="header-stock.jpg" 
				height="shallow" 
				title="Stock & Delivery" 
				description="Keep up to date with the latest stock and delivery updates for all your favourite Tropic products"/>
    	<div className="Container">
    		<Iframe url="https://infogram.com/1pj5ne25jl37q3h6yy9y6vd571umg0drk93?src=embed"
        	id="stockDeliveryIframe"
        	position="relative"
        	 />
       	</div>
  		</>
	)
}

export default Screen;