/**

The AmbassadorTitle component takes the title short code (AM, LDR etc)
and renders the full title name

*/

// Packages
import PropTypes from 'prop-types';


const AmbassadorTitle = props =>
{
	/*
	Define a mapping between the title short code
	and the full title name
	*/
	const titles = {
		"AM" : "Ambassador",
		"SA" : "Senior Ambassador",
		"TA" : "Team Ambassador",
		"LDR" : "Leader",
		"SL" : "Senior Leader",
		"EX" : "Executive",
		"DE" : "Diamond Executive",
		"PE" : "Platinum Executive"
	};


	/*
	 * Function which returns the full name for the title
	 * using the shortcode as the key.
	 * 
	 * If the key is not found, then the shortcode will be
	 * returned
	 */
	const getTitleName = (titleInitials) => 
	{
		if ( titles[titleInitials] )
		{
			return titles[titleInitials];
		}
		else
		{
			// Key doesn't exist
			return titleInitials;
		}
	}

	return (
		<>
		{getTitleName(props.title)}
		</>
	);
}


// Prop Types
AmbassadorTitle.propTypes = {
	title: PropTypes.string.isRequired
};


export default AmbassadorTitle;