// Packages
import { useState } from 'react';


// Components
import Loading from 'components/UI/Loading';


// Other
import Api from 'other/Api';


// Assets
import './styles.css';


const Component = () => {

	// State
	const [ newPassword, setNewPassword ] = useState('');
	const [ newPassword2, setNewPassword2 ] = useState('');
	const [ loading, setLoading ] = useState(false);
	const [ errorMessage, setErrorMessage ] = useState();
	const [ successMessage, setSuccessMessage ] = useState();


	/**	
	 * Form Submit action
	 */
	const submit = async () => {

		// Clear result messages
		setErrorMessage('');
		setSuccessMessage('');


		// Check if a password has been entered
		if ( newPassword.trim() === '' || newPassword2.trim() === '' )
		{
			setErrorMessage('Please enter a new password');
			return;
		}


		// Check that the password and the retype match
		if ( newPassword !== newPassword2 )
		{
			setErrorMessage('Your passwords do not match');
			return;
		}

		Api.password.change(newPassword, {
			before: () => {
				setLoading(true);
			},	
			success: (response) => {
				setSuccessMessage('Your password has been changed');

				setNewPassword('');
				setNewPassword2('');

				setTimeout(() => {
					window.location.reload();
				}, 3000);
			},
			error: (error) => {
				setErrorMessage(error.error);
			},
			after: () => {
				setLoading(false);
			}
		});
	}



	if ( loading )
	{
		return <Loading layout="vertical" message="Setting your new password"/>;
	}


	return (
		<>

		{ errorMessage &&
			<div className="ChangePassword__result ChangePassword__result--error">{errorMessage}</div>
		}

		{ successMessage &&
			<div className="ChangePassword__result ChangePassword__result--success">{successMessage}</div>
		}

		<form className="UserProfileScreen__form" autoComplete="off" onSubmit={(e) => {
				
			e.preventDefault();
			submit();
		}}>




			<input value={newPassword} onChange={(e) => {
				setNewPassword(e.target.value);
			}} type="password" className="UserProfileScreen__form__input UserProfileScreen__form__input--bottommargin" placeholder="New password" name="password"/>
			
			<br/>

			<input value={newPassword2} onChange={(e) => {
				setNewPassword2(e.target.value);
			}} type="password" className="UserProfileScreen__form__input" placeholder="New password (again)" name="password2"/>
			
			<button style={{marginTop: '10px'}} className="button">Set New Password</button>
		
		</form>

		</>

	);
}


export default Component;