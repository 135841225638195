// Libraries
import { useState } from 'react';
import PropTypes from 'prop-types';


// Components
import Avatar from 'components/Avatar';
import AmbassadorTitle from 'components/AmbassadorTitle'


// Screens
import ChangePhoto from './ChangePhoto';
import ChangePassword from './ChangePassword';


// Assets
import './styles.css';




const Screen = props => {

	// Props
	const { user, logout } = props;


	// State
	const [ screen, setScreen ] = useState('default');

	

	if ( 'photo' === screen )
	{
		return (
			<div className="UserProfileScreen">
			<div onClick={() => {
					setScreen('default');
				}} className="UserProfileScreen__back"></div>
				<ChangePhoto user={user}/>
			</div>
		);

	}
	else if ( 'password' === screen )
	{
		return (
			<div className="UserProfileScreen">
			<div onClick={() => {
					setScreen('default');
				}} className="UserProfileScreen__back"></div>
				<ChangePassword user={user}/>
			</div>
		);

	}
	else
	{
		return (
		<div className="UserProfileScreen">

			<div className="UserProfileScreen__avatar">
				<Avatar showName={false} size="large" firstname={user.given_name} lastname={user.family_name} picture={user['https://id.tropicskincare.com/picture_url']}/>
			</div>

			<div className="UserProfileScreen__title UserProfileScreen__title--large">
				{user.given_name} {user.family_name}
			</div>

			<br/>

			<div className="UserProfileScreen__info">
				<div className="UserProfileScreen__info__name">
					Your Tropic title is
				</div>
				<div className="UserProfileScreen__info__value">
					<AmbassadorTitle title={user['https://id.tropicskincare.com/title']}/>
				</div>
			</div>

			<div className="UserProfileScreen__info">
				<div className="UserProfileScreen__info__name">
					Your official Ambassador ID is
				</div>
				<div className="UserProfileScreen__info__value">
					{user['https://id.tropicskincare.com/ambassador_id']}
				</div>
			</div>

			<br/>

			{/*
			<button onClick={() => {

				setScreen('photo');
				
			}} className="UserProfileScreen__action">Change your profile photo</button>
			
			<br/>
			<br/>
			*/}
			

			<button onClick={() => {

				setScreen('password');
				
			}} className="UserProfileScreen__action">Change your password</button>
			
			<br/>
			<br/>

			<button onClick={() => {
				logout({ returnTo: window.location.origin })
			}} className="UserProfileScreen__action">Sign Out</button>

		</div>
	);
}
	
}


Screen.propTypes = {
	user: PropTypes.object.isRequired
}


export default Screen;