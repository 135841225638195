// Components
import ChangePassword from 'components/Auth0/ChangePassword';


const Screen = props => {

	return (
			<>
			<div className="UserProfileScreen__title">
				Change Your Password
			</div>

			<p>To change your password enter the new password into both boxes below.</p>

			<p><strong>You will need to re-login after changing your password.</strong></p>

			<ChangePassword/>

			<p><b>Your new password must:</b><br/>
				 - be 8 characters or more<br/>
				 - contain a 'special' character (!@#$%^&*)<br/>
				 - contain lower case (a-z), upper case (A-Z) and numbers (0-9)
			</p>			
			
			</>
		);
}

export default Screen;