// Packages
import axios from 'axios';


// Other
import Config from 'other/Config';



/** 
 * Adds defaults to all requests
 */
const setDefaults = (args) => {

    const defaults = {
        // Triggered before request
        before: () => {},
        // Triggered after request (success or error)
        after: () => {},
        // Triggered on a successful request
        success: () => { alert('There is no success callback'); },
        // Triggered on an error
        error: (error) => { alert('An unhandled error occurred: ' + error.message); },
        // GET parameters
        params: {},
        // POST body data
        data: {}
    };

    for ( const name in defaults )
    {
        if ( ! args[name] )
        {
            args[name] = defaults[name]
        }
    }

    return args;
}




/**
 * The code that actually makes the API calls
 */
const apiRequest = async (method, url, request) => {

    const params = setDefaults(request);

    const token = await getAccessToken();

    params.before();

    if ( url.substring(0, 4) !== 'http' )
    {
    	url = Config.API_URL + url;
    }

    axios.request({
        url: url,
        method: method.toLowerCase(),
        params: params.params,
        data: params.data,
        responseType: 'json',
        headers: {
        	Authorization: 'Bearer ' + token
        }
    })
    .then((response) => {
        params.success(response.data);
    })
    .catch((exception) => {
        console.log(exception);
        params.error(exception.response.data);
    })
    .then(() => {
        params.after()
    });
}



/**	
 * Return the access token from local storage
 */
const getAccessToken = async () => {

	return sessionStorage.getItem('access-token');
}


/**	
 * Change the user's password
 */
const changePassword = async (password, request) => {
    apiRequest('post', '/change-password', {
        ...request,
        data: {
            password: password
        }

    });
}


/**	
 * Change the user's photo
 */
const changePhoto = async (file, request) => {

	const data = new FormData();
    data.append('file', file);

    apiRequest('post', '/upload-photo', {
    	...request,
    	data: data
    });
}


/**	
 * Remove the user's photo
 */
const removePhoto = async (request) => {
    apiRequest('get', '/remove-photo', request);
}


/**
 * Get the app status
 */
const getAppStatus = async (request) => {
    apiRequest('get', Config.APPSTATUS_URL + '?ts=' + Date.now(), request);
}



const output = {
	getAppStatus,
    password: {
        change: changePassword
    },
    photo: {
        change: changePhoto,
        remove: removePhoto
    }
};

export default output;
