// Assets
import './styles.css'


const Overlay = props =>
{
	
	return (
		<div className="overlay" onClick={props.onClick}>
			{props.children}
		</div>
	)
}

export default Overlay