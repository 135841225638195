// Packages
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';


// Components
import Overlay from 'components/UI/Overlay';
import Avatar from 'components/Avatar';
import ModalWindow from 'components/UI/ModalWindow';


// Screens
import UserProfileScreen from 'screens/UserProfile';


// Assets
import './styles.css';
import tropicLogo from 'assets/logo-2022.svg';


const Header = props => 
{
	// State
  	const [ drawerActive, setDrawerActive ] = useState(false);


	/*
	Import variables from the Auth0 context
	*/
	const {
    	user,
    	logout
  	} = useAuth0();


  	/**
  	 * Function to toggle the 'drawer' status
  	 */
  	const toggleDrawer = () => {
  		setDrawerActive(!drawerActive)
  	}


	return (
		<header className="Container Header">
			<a href="/" className="Header__logo"><img src={tropicLogo} alt="Tropic Skincare" className="Header__logo__img"/></a>
			<div className="Header__user" onClick={toggleDrawer}>
				<Avatar firstname={user.given_name} lastname={user.family_name} picture={user['https://id.tropicskincare.com/picture_url']}/>
			</div>
			{ drawerActive && 
				<Overlay onClick={toggleDrawer}>
					<ModalWindow onClose={toggleDrawer}>
						<UserProfileScreen logout={logout} user={user}/>
					</ModalWindow>
				</Overlay>
			}
		</header>
	);
}


export default Header;