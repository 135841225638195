// Packages
import PropTypes from 'prop-types';


// Assets
import './styles.css';


const Banner = props => {

	// Props
	const { title, description, height, photo } = props;


	let style = {};

	if ( photo )
	{
		style.backgroundImage = "url('" + require('assets/' + photo).default + "')";
	}


	return (
		<div className={`Banner Banner--height-${height}`} style={style}>
			<div className="Container">
				<div className="Banner__body">
					<h1>{title}</h1>
					<p>{description}</p>
				</div>
			</div>
		</div>
	);
}


// Prop Types
Banner.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	height: PropTypes.string,
	photo: PropTypes.string.isRequired
};


// Default Props
Banner.defaultProps = {
	height: 'default'
}


export default Banner;