// Components
import Header from 'components/Header'


const DefaultLayout = props => {

	return (
		<div className="DefaultLayout">
			<Header/>
			<div className="DefaultLayout__main">
				{props.children}
			</div>
		</div>
	)
}


export default DefaultLayout