// Packages
import { useState } from 'react';
import PropTypes from 'prop-types';


// Components
import Loading from 'components/UI/Loading';


// Other
import Api from 'other/Api';


const Component = props => {


	// Props
	const { onChange } = props;


	// State
	const [ loading, setLoading ] = useState(false);
	const [ errorMessage, setErrorMessage ] = useState();
	const [ successMessage, setSuccessMessage ] = useState();


	/**
	 * Upload the new photo
	 */
	const uploadFile = async (file) => {

		Api.photo.change(file, {
			before: () => {
				setLoading(true);
			},	
			success: (response) => {
				setSuccessMessage('Your photo has been changed');
				onChange(response.data.avatar_url);

				setTimeout(() => {
					window.location.reload();
				}, 3000);
			},
			error: (error) => {
				setErrorMessage(error.message);
			},
			after: () => {
				setLoading(false);
			}
		});
	}


	/**
	 * Remove the user's existing photo
	 */
	const removePhoto = async () => {

		Api.photo.remove({
			before: () => {
				setLoading(true);
			},	
			success: (response) => {
				setSuccessMessage('Your photo has been changed');
				onChange(response.data.avatar_url);

				setTimeout(() => {
					window.location.reload();
				}, 3000);
			},
			error: (error) => {
				setErrorMessage(error.message);
			},
			after: () => {
				setLoading(false);
			}
		});
	}


	if ( loading )
	{
		return <Loading layout="vertical" message="Updating your photo"/>;
	}

	
	return (
		<>

		{ errorMessage &&
			<div className="ChangePassword__result ChangePassword__result--error">{errorMessage}</div>
		}

		{ successMessage &&
			<div className="ChangePassword__result ChangePassword__result--success">
				<b>{successMessage}</b><br/>
				<span style={{opacity: 0.75}}>(Reloading page in 3 seconds)</span>
			</div>
		}

		<form className="UserProfileScreen__form">
		
			<div className="UserProfileScreen__form__description">
				<p>You can upload a new profile photo by selecting a file from your computer below.</p>
			</div>

			<input type="file" className="UserProfileScreen__form__input" name="file" onChange={(event) => {
				uploadFile(event.target.files[0]);
			}}/>

		</form>

		<button className="button button--text button--delete" onClick={removePhoto}>Remove your photo</button>

		</>
	);
}


Component.propTypes = {
	onChange: PropTypes.func
}

export default Component;